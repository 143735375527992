import {
    addMinutes,
    format,
    formatDistance,
    formatDuration,
    intervalToDuration,
    intlFormatDistance,
    parseISO,
} from "date-fns";
import { es } from "date-fns/locale";
import type { InvoicePaymentCFDI } from "@/types";

export const humanEconomicActivityFlag = (flag: string) => {
    return (
        {
            ENTITY_IS_DOCTOR_OR_DENTIST: "IVA Exento para Doctores y Dentistas",
            ENTITY_HAS_IVA_INCENTIVE: "Incentivo de IVA al 50%",
            ACTIVITY_IS_ZERO_PERCENT: "IVA al 0% (productos primarios)",
            ACTIVITY_IS_SIX_PERCENT: "IVA al 6% (fletes)",
            ACTIVITY_IS_EXPORT_WITH_FOREIGN_PAYMENT:
                "Exportación de servicios con pago en el extranjero",
            ACTIVITY_IS_PPD: "Pago en parcialidades o diferido",
            ACTIVITY_SKIPS_IVA_WITHHOLDING:
                "Sin retención de IVA (colaboraciones, productos)",
            ACTIVITY_SKIPS_ISR_WITHHOLDING:
                "Sin retención de ISR (comisiones, fletes, productos)",
            ALCOHOL_BEVERAGE_RANGE_20_100:
                "IEPS, Bebidas alcohólica entre 20% a 100% vol.",
            ALCOHOL_BEVERAGE_RANGE_14_20:
                "IEPS, Bebidas alcohólica entre 14% a 20% vol.",
            ALCOHOL_BEVERAGE_RANGE_0_14:
                "IEPS, Bebidas alcohólica entre 0% a 14% vol.",
            null: "IVA al 16%",
        }[flag] ?? flag
    );
};

export const humanPaymentGateway = (payment: string) => {
    return (
        {
            99: "Por definir",
            CASH: "Efectivo",
            CHECK: "Cheque nominativo",
            DEBIT_CARD: "Tarjeta de debito",
            CREDIT_CARD: "Tarjeta de crédito",
            SERVICE_CARD: "Tarjeta de servicios",
            BANK_TRANSFER: "Transferencia Bancaria",
            TERMINAL_MSI_3: "3 Meses sin intereses",
            TERMINAL_MSI_6: "6 Meses sin intereses",
            TERMINAL_MSI_9: "9 Meses sin intereses",
            TERMINAL_MSI_12: "12 Meses sin intereses",
            TERMINAL_MSI_18: "18 Meses sin intereses",
            TERMINAL_MSI_24: "24 Meses sin intereses",
        }[payment] ??
        humanPaymentType(payment, true, true) ??
        payment
    );
};

export const humanPlan = (id: string) => {
    return "???";
};

export const humanDate = (date: string) => {
    return format(parseISO(date), "PPP", { locale: es });
};

export const humanShortDate = (date: string) => {
    return format(parseISO(date), "P", { locale: es });
};

export const humanMonthWithYear = (date: string) => {
    return format(parseISO(date), "MMMM yyyy", { locale: es });
};

export const humanDayNumber = (date: string) => {
    return format(parseISO(date), "d", { locale: es });
};

export const humanHour = (date: string) => {
    return format(parseISO(date), "p");
};

export const humanMonth = (date: string) => {
    return format(parseISO(date), "MMMM", { locale: es });
};

export const humanMonthYear = (date: string) => {
    return format(parseISO(date), "MMM yyyy", { locale: es });
};

export const humanCfdi = (cfdi: InvoicePaymentCFDI | null): string => {
    if (!cfdi) return "No";

    if (cfdi === "CFDI_CAPTURE") {
        return "Contribuyente";
    }

    if (cfdi === "CFDI_GLOBAL") {
        return "Global";
    }

    if (cfdi === "CFDI_PENDING") {
        return "Pendiente";
    }
};

export const humanMoney = (number: string | number) => {
    return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
    }).format(Number(number));
};

export const humanDifference = (minutes) => {
    const start = new Date();

    return formatDuration(
        intervalToDuration({
            start,
            end: addMinutes(start, minutes ? minutes : 0),
        }),
        { locale: es }
    );
};

export const humanDistance = (date) => {
    const start = new Date();

    return intlFormatDistance(parseISO(date), new Date(), { locale: "es" });
};

export const humanPaymentMethod = (
    paymentMethod: string,
    includeCode = true
) => {
    if (includeCode) {
        return `${paymentMethod} - ${humanPaymentMethod(paymentMethod, false)}`;
    }

    return (
        {
            PUE: "Pago en una sola exhibición",
            PPD: "Pago en parcialidades o diferido",
        }[paymentMethod] ?? paymentMethod
    );
};

export const humanPaymentType = (
    paymentType: string,
    includeCode = true,
    returnNull = false
) => {
    if (includeCode) {
        return `${paymentType} - ${humanPaymentType(paymentType, false)}`;
    }

    return (
        {
            "01": "Efectivo",
            "02": "Cheque nominativo",
            "03": "Transferencia electrónica de fondos",
            "04": "Tarjeta de crédito",
            "05": "Monedero electrónico",
            "06": "Dinero electrónico",
            "08": "Vales de despensa",
            "12": "Dación en pago",
            "13": "Pago por subrogación",
            "14": "Pago por consignación",
            "15": "Condonación",
            "17": "Compensación",
            "23": "Novación",
            "24": "Confusión",
            "25": "Remisión de deuda",
            "26": "Prescripción o caducidad",
            "27": "A satisfacción del acreedor",
            "28": "Tarjeta de débito",
            "29": "Tarjeta de servicios",
            "30": "Aplicación de anticipos",
            "31": "Intermediario pagos",
            "99": "Por definir",
        }[paymentType] ?? (returnNull ? null : paymentType)
    );
};

export const humanTaxSystem = (taxSystem: string, includeCode = true) => {
    if (includeCode) {
        return `${taxSystem} - ${humanTaxSystem(taxSystem, false)}`;
    }

    return (
        {
            "601": "General de Ley Personas Morales",
            "603": "Personas Morales con Fines no Lucrativos",
            "605": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            "606": "Arrendamiento",
            "607": "Régimen de Enajenación o Adquisición de Bienes",
            "608": "Demás ingresos",
            "610": "Residentes en el Extranjero sin Establecimiento Permanente en México",
            "611": "Ingresos por Dividendos (socios y accionistas)",
            "612": "Personas Físicas con Actividades Empresariales y Profesionales",
            "614": "Ingresos por intereses",
            "615": "Régimen de los ingresos por obtención de premios",
            "616": "Sin obligaciones fiscales",
            "620": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            "621": "Incorporación Fiscal",
            "622": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            "623": "Opcional para Grupos de Sociedades",
            "624": "Coordinados",
            "625": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            "626": "Régimen Simplificado de Confianza",
        }[taxSystem] ?? taxSystem
    );
};

export const humanCFDIUse = (use: string, includeCode = true) => {
    if (includeCode) {
        return `${use} - ${humanCFDIUse(use, false)}`;
    }

    return (
        {
            G01: "Adquisición de mercancías",
            G02: "Devoluciones, descuentos o bonificaciones",
            G03: "Gastos en general",
            I01: "Construcciones",
            I02: "Mobiliario y equipo de oficina por inversiones",
            I03: "Equipo de transporte",
            I04: "Equipo de computo y accesorios",
            I05: "Dados, troqueles, moldes, matrices y herramental",
            I06: "Comunicaciones telefónicas",
            I07: "Comunicaciones satelitales",
            I08: "Otra maquinaria y equipo",
            D01: "Honorarios médicos, dentales y gastos hospitalarios",
            D02: "Gastos médicos por incapacidad o discapacidad",
            D03: "Gastos funerales",
            D04: "Donativos",
            D05: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
            D06: "Aportaciones voluntarias al SAR",
            D07: "Primas por seguros de gastos médicos",
            D08: "Gastos de transportación escolar obligatoria",
            D09: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
            D10: "Pagos por servicios educativos (colegiaturas)",
            S01: "Sin efectos fiscales",
            CP01: "Pagos",
            CN01: "Nómina",
        }[use] ?? use
    );
};
