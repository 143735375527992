import "../css/app.css";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import JetButton from "@/Jetstream/Button.vue";
import JetFormSection from "@/Jetstream/FormSection.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetInputError from "@/Jetstream/InputError.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetActionMessage from "@/Jetstream/ActionMessage.vue";
import JetSecondaryButton from "@/Jetstream/SecondaryButton.vue";

import JetCheckbox from "@/Jetstream/Checkbox.vue";
import {
    humanDate,
    humanMoney,
    humanPaymentGateway,
    humanPlan,
    humanShortDate,
} from "@/Utils/human";
import { handleQuickScroll } from "@/Utils/documentHelpers";

import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

import { createPinia } from "pinia";

import { createApp, type DefineComponent, h } from "vue";
import { createInertiaApp, Head, Link, router } from "@inertiajs/vue3";
import { route } from "ziggy-js";

import axios from "axios";

import * as Sentry from "@sentry/vue";
import { setDefaultOptions } from "date-fns";
import { es } from "date-fns/locale";

setDefaultOptions({
    locale: es,
});

const pinia = createPinia();

window.inertiaEventsCount = {
    navigateCount: 0,
    successCount: 0,
    errorCount: 0,
};

createInertiaApp({
    progress: {
        color: "#10b623",
    },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props),
            mounted() {
                router.on("navigate", (event) => {
                    window.inertiaEventsCount.navigateCount++;
                });

                router.on("success", (event) => {
                    window.inertiaEventsCount.successCount++;
                });

                router.on("error", (event) => {
                    window.inertiaEventsCount.errorCount++;
                });
            },
        })
            .mixin({
                components: {
                    JetButton,
                    JetFormSection,
                    JetInput,
                    JetInputError,
                    JetLabel,
                    JetActionMessage,
                    JetSecondaryButton,
                    JetCheckbox,
                    InertiaLink: Link,
                    Head: Head,
                },
            })
            .mixin({
                methods: {
                    route,
                    humanDate,
                    humanMoney,
                    humanPaymentGateway,
                    humanShortDate,
                    handleQuickScroll,
                    humanPlan,
                },
            });

        const client = Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [
                Sentry.replayIntegration(),
                Sentry.browserTracingIntegration({
                    // disable automatic span creation
                    instrumentNavigation: false,
                    instrumentPageLoad: false,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
            tracePropagationTargets: ["localhost"],
        });

        const sentryTrace = document.querySelector<HTMLMetaElement>(
            "meta[name=sentry-trace]"
        )?.content;
        const baggage =
            document.querySelector<HTMLMetaElement>(
                "meta[name=baggage]"
            )?.content;

        Sentry.startBrowserTracingPageLoadSpan(client, {
            name: route().current(),
            attributes: {
                [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
            },
        });

        let name = route().current();

        router.on("finish", () => {
            const newName = route().current();

            if (name !== newName) {
                Sentry.startBrowserTracingNavigationSpan(client, {
                    op: "navigation",
                    name, // or what the name of the span should be
                    attributes: {
                        [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
                    },
                });
                name = newName;
            }
        });

        app.use(plugin).use(moshaToast).use(pinia).mount(el);

        axios.interceptors.request.use(function (config) {
            config.headers["baggage"] = sentryTrace;
            config.headers["sentry-trace"] = baggage;
            return config;
        });
    },
});
