<template>
    <div :class="noTitle ? '' : 'md:grid-cols-3'" class="md:grid md:gap-6">
        <jet-section-title :dusk="dusk" v-if="!noTitle">
            <template #title>
                <slot name="title"></slot>
            </template>
            <template #description>
                <slot name="description"></slot>
            </template>
        </jet-section-title>

        <div class="mt-5 md:col-span-2 md:mt-0">
            <component
                :is="noForm ? 'div' : 'form'"
                @submit.prevent="$emit('submitted')"
            >
                <slot name="formContainer">
                    <div
                        :class="[
                            hasActions
                                ? 'sm:rounded-tl-md sm:rounded-tr-md'
                                : 'sm:rounded-md',
                            noTitle ? '' : 'bg-white px-4 py-5 shadow sm:p-6',
                        ]"
                    >
                        <slot name="formGrid">
                            <div class="grid grid-cols-6 gap-3">
                                <slot name="form"></slot>
                            </div>
                        </slot>
                    </div>
                </slot>

                <div
                    class="flex items-center justify-end gap-4 bg-gray-50 px-4 py-3 text-right shadow empty:hidden sm:rounded-bl-md sm:rounded-br-md sm:px-6"
                >
                    <slot name="actions"></slot>
                </div>
            </component>
        </div>
    </div>
</template>

<script lang="ts">
import JetSectionTitle from "./SectionTitle.vue";

export default {
    props: {
        noForm: Boolean,
        noTitle: Boolean,
        dusk: { type: String, default: null },
    },
    emits: ["submitted"],

    components: {
        JetSectionTitle,
    },

    computed: {
        hasActions() {
            return !!this.$slots.actions;
        },
    },
};
</script>
