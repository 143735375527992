<template>
    <input
        ref="input"
        :value="modelValue"
        class="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        @input="
            $emit(
                'update:modelValue',
                ($event.target as HTMLInputElement).value
            )
        "
    />
</template>

<script lang="ts">
import type { InputHTMLAttributes } from "vue";

export default {
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },

    emits: ["update:modelValue"],

    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>
