<template>
    <div v-show="message">
        <p class="text-sm text-red-600">
            {{ message }}
        </p>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
    },
};
</script>
